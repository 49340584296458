import axios from "axios";
import apiModel from "../model/apiModel";
import store from "../../../store/index";

axios.defaults.baseURL = "https://cpadmin.cat.com.eg/api/v3/";

// main :- https://cpadmin.cat.com.eg/api/v3/
//test :- https://cat-purchasing-app.cat-sw.com/api/v3/
//https://cpadmin.cat.com.eg/api/
require("./interceptors");
// let axiosConfig = {
//   headers: {
//       'Content-Type': 'application/x-www-form-urlencoded',
//   }
// };

export class ApiService {
  constructor() {
    this.apiModel = apiModel.apiModel;
  }

  get(Url) {
    return axios
      .get(`${Url}`)
      .then((res) => {
        return this.dataStats(res);
      })
      .catch((err) => {
        this.serverResponse(err);
      });
  }
  getFilter(Url, data) {
    return axios
      .get(`${Url}/${data}`)
      .then((res) => {
        return this.dataStats(res);
      })
      .catch((err) => {
        // console.log(err);
        this.serverResponse(err);
      });
  }
  getPayload(Url, data) {
    return axios
      .get(Url, data)
      .then((res) => {
        return this.dataStats(res);
      })
      .catch((err) => {
        // console.log(err);
        this.serverResponse(err);
      });
  }
  post(Url, data) {
    return axios
      .post(Url, data)
      .then((res) => {
        return this.dataStats(res);
      })
      .catch((err) => {
        this.serverResponse(err);
      });
  }
  put(Url, data) {
    return axios
      .put(Url, data)
      .then((res) => {
        return this.dataStats(res);
      })
      .catch((err) => {
        this.serverResponse(err);
      });
  }
  update(id, Url, data) {
    return axios
      .put(`${Url}/` + id, data, {})
      .then((res) => {
        return this.dataStats(res);
      })
      .catch((err) => {
        this.serverResponse(err);
      });
  }
  updateWithURL(Url, data) {
    return axios
      .put(`${Url}`, data, {})
      .then((res) => {
        return this.dataStats(res);
      })
      .catch((err) => {
        this.serverResponse(err);
      });
  }

  delete(Url) {
    return axios
      .delete(`${Url}`)
      .then((res) => {
        return this.dataStats(res);
      })
      .catch((err) => {
        this.serverResponse(err);
      });
  }

  dataStats(data) {
    this.apiModel = data.data;
    switch (data.status) {
      case 200:
        return this.apiModel ? this.apiModel : true;
      case 201:
        store.commit("setApiMessage", this.apiModel.msg);
        store.commit("UPDATE_DIALOG", true);
        setTimeout(() => store.commit("UPDATE_DIALOG", false), 3000);
        return this.apiModel ? this.apiModel : true;
      case 404:
        return false;
      default:
        return false;
    }
  }

  serverResponse(err) {
    let message = err.errs;
    if (err.success == 0) {
      store.commit("setApiMessage", err);
      store.commit("UPDATE_DIALOG", true);
    }
    if (message) {
      store.commit("setApiMessage", message);
      store.commit("UPDATE_DIALOG", true);
    }
  }
}
