import axios from "axios";
import router from "../../../router/index";
import store from "../../../store/index";

axios.interceptors.request.use(
  function (config) {
    const token = store.getters.token ? `Bearer ${store.getters.token}` : "";
    if (token) config.headers.Authorization = token;
    return config;
  },
  function (err) {
  
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (response) => {
    //refreshToken();
    /*         let exp = parseInt(store.getters.expires_at);
       const now = Date.now() / 1000;
       let timeUntilRefresh = exp - now;
       console.log("Notttt refreshed", timeUntilRefresh);
       if (timeUntilRefresh < 50 && store.getters.isRefresh == false) {
         console.log("token is refreshed", timeUntilRefresh);
      
          axios
          .post("auth/refresh")
          .then((res) => {
            store.commit("UPDATE_ISREFRESH",true);
            console.log( "data",res.data);
            store.commit("setToken", res.data.access_token);
              store.commit("setUser", res.data.me);
              store.commit("setExpires_at", res.data.expires_at);
              return response; 
          })
          .catch((error) => {
            console.error(error);
       });
       
         
       } else   */
    return response;
  },
  (err) => {
    // console.log('errooooooooooor', err.response)
    const {
      response: { status },
    } = err;
    if (err.response) {
      store.commit("setApiMessage", err.response.data.error);
  }

    /*   let exp = parseInt(store.getters.expires_at);
      console.log(exp);
       const now = Date.now() / 1000;
       let timeUntilRefresh = exp - now;
       console.log("Notttt refreshed", timeUntilRefresh);
       if (timeUntilRefresh < 55) {
         console.log("token is refreshed", timeUntilRefresh);
      
          axios
          .post("auth/refresh")
          .then((res) => {
            store.commit("UPDATE_ISREFRESH",true);
            console.log( "data",res.data);
              store.commit("setToken", res.data.access_token);
              store.commit("setUser", res.data.me);
              store.commit("setExpires_at", res.data.expires_at);
          })
          .catch((error) => {
            console.error(error);
       });
        */
   
    if (status === 401) {
      store.commit("setToken", null);
      store.commit("setUser", null);
      store.commit("setExpires_at", null);
      router.push({ name: "auth" });
    }
  }
);
